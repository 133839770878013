import { PlusSquareTwoTone } from "@ant-design/icons";
import { Avatar, Button, Divider, Form, List, Modal, Popover, Radio, Switch, message } from "antd";
import { useEffect, useState } from "react";
import { useGlobalState } from "../../components/global";
import { SceneForm } from "./Form";
import './List.css';

export default function SceneList() {
  const { currentTenant, jsonRequest } = useGlobalState();
  const [loading, setLoading] = useState(false);
  const [listData, setData] = useState<any>({});
  const [channelData, setChannelData] = useState<any>([]);
  const [channelKey, setChannelKey] = useState<any>('');
  const [form] = Form.useForm();
  let modal: any;
  const handleFormOk = async () => {
    let values = await form.validateFields();
    if (values.content.type > 2) {
      values.content.content = "";
    }
    if (values.external[0].type === 0) {
      values.external[0] = { type: 0, text: '', url: '', params: {} };
    }
    if (values.id) {
      jsonRequest.post(`/api/request`, {
        path: "/v1/notification/scene/modify",
        method: "post",
        application_key: "zy:services::notification",
        tenant_meta: currentTenant,
        data: {
          ...values,
          status: 1
        }
      })
        .then((resp: any) => resp.json())
        .then((data: any) => {
          if (data.code === 0) {
            message.success("编辑成功");
            form.resetFields();
            handleFormCancel();
          } else {
            message.error(data.msg);
          }
        }
        )
    } else {
      jsonRequest.post(`/api/request`, {
        path: "/v1/notification/scene/add",
        method: "post",
        application_key: "zy:services::notification",
        tenant_meta: currentTenant,
        data: {
          ...values
        }
      })
        .then((resp: any) => resp.json())
        .then((data: any) => {
          if (data.code === 0) {
            message.success("新增成功");
            form.resetFields();
            handleFormCancel();
          } else {
            message.error(data.msg);
          }
        }
        )
    }
    getData(listData.pageNumber, listData.pageSize);
  }

  const handleFormCancel = () => {
    form.resetFields();
    form.setFieldsValue({
      id: undefined,
      channelKey: undefined,
      content: { title: undefined, brief: undefined, content: undefined, logo: undefined },
      defaultPush: { title: undefined, content: undefined, url: undefined, params: {} },
      external: [{ type: 0, text: undefined, url: undefined, params: {} }],
      sceneKey: undefined,
      sceneName: undefined,
      variables: {}
    });
    modal?.destroy();
  }

  const handleFormModal = (item?: string) => {
    Modal.confirm({
      title: '场景设置',
      content: <SceneForm form={form} initialValues={item} channelData={channelData} />,
      width: '100vw',
      onOk: handleFormOk,
      onCancel: handleFormCancel,
      okText: '提交',
      cancelText: '取消'
    });
  }

  useEffect(() => {
    getChannelData();
  }, []);

  useEffect(() => {
    getData(1, 12);
  }, [currentTenant, channelKey]);

  const getData = async (pageNumber: number, pageSize: number) => {
    if (loading) {
      return;
    }
    setLoading(true);
    // 获取场景列表
    jsonRequest.post("/api/request",
      {
        path: `/v1/notification/scene/pageQuery`,
        method: "get",
        application_key: "zy:services::notification",
        tenant_meta: currentTenant,
        data: {
          pageNumber,
          pageSize,
          channelKey
        },
      })
      .then((resp: any) => resp.json())
      .then(async ({ data }: any) => {
        setData(data);
        setLoading(false);
      })
      .catch(() => {
        setLoading(false);
      });
  };

  const getChannelData = async () => {
    // 获取频道下拉数据
    jsonRequest.post("/api/request",
      {
        path: `/v1/notification/channel/queryAll`,
        method: "get",
        application_key: "zy:services::notification",
        tenant_meta: currentTenant,
        data: {},
      })
      .then((resp: any) => resp.json())
      .then(async ({ data }: any) => {
        setChannelData(data);
      });
  };

  const handleEnableChange = (item: any, status: boolean) => {
    jsonRequest.post("/api/request", {
      path: `/v1/notification/scene/modify`,
      method: "post",
      application_key: "zy:services::notification",
      tenant_meta: currentTenant,
      data: { ...item, status },
    }).then((resp: any) => resp.json())
      .then(() => {
        message.success(`${status ? "启用" : "禁用"}成功`);
      })
    getData(listData.pageNumber, listData.pageSize);
  }

  return <div className="page notification-scene">
    <h1>通知场景管理 <Button onClick={() => handleFormModal()} type="link" size="small" icon={<PlusSquareTwoTone />}>新增自定义场景</Button></h1>
    <div style={{ display: "flex", alignItems: "center" }}>
      频道：<Radio.Group
        options={[
          { value: '', label: '全部' },
          ...channelData?.map((e: any) => ({ value: e?.key, label: e?.name })) || []
        ]}
        value={channelKey}
        onChange={(e) => setChannelKey(e?.target?.value)}
        optionType="button"
      />
    </div>
    <br />
    <div className="content">
      <List
        loading={loading}
        rowKey={d => d.meta}
        dataSource={listData.list}
        pagination={{
          onChange: (page, size) => { getData(page, size) },
          total: listData.total,
          current: listData.pageNumber,
          pageSize: listData.pageSize,
          showQuickJumper: true
        }}
        grid={{ gutter: 16, column: 4 }}
        itemLayout="vertical"
        size="large"
        renderItem={(item: any) => (
          <List.Item
            key={item.id}
            className="item-scene"
            actions={[<div>
              <Button size="small" type="link" onClick={() => handleFormModal(item)}>详情/修改</Button>
              <Divider type="vertical" />
              <Switch checked={item.status} checkedChildren="启用" unCheckedChildren="禁用" size="small" onChange={(checked) => handleEnableChange(item, checked)} />
            </div>]}>
            <List.Item.Meta
              title={
                <div>
                  {item?.content?.logo && (<Avatar src={item?.content?.logo} />)}
                  <span style={{ color: 'orange' }}>[{item?.channelName}]</span>
                  <span>{item?.sceneName}</span>
                </div>}
              description={<div>
                <div style={{ height: '100px', overflowY: 'auto' }}>
                  <p>{item.content.brief}</p>
                  {item.external[0].type === 1 && (
                    <div>
                      <p style={{ color: 'gray' }}>额外操作：
                        <Popover content={item.external[0].url}>
                          <Button type="dashed" danger>{item.external[0].text || "列表中点击直接执行"}</Button>
                        </Popover>
                      </p>
                    </div>
                  )}
                </div>
                <div>01-01 00:00:00</div>
              </div>}
            />
          </List.Item >
        )}>
      </List>
    </div>
  </div >
}