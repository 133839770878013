import { Form, Input, Image, InputNumber, Select } from "antd";
import UploadComponent from "../../components/UploadComponent";
import { useEffect, useState } from "react";
import { PlusOutlined } from "@ant-design/icons";
import { useGlobalState } from "../../components/global";

declare var window: any;

export function ChannelForm({ form, item }: any) {
    const [fileList, setFileList]: any = useState([]);
    const [status, setStatus]: any = useState([]);
    const [tenantMeta, setTenantMeta] = useState<any>();
    const { jsonRequest, currentTenant } = useGlobalState();

    const handleUploadSuccess = (fileList: any) => {
        const images = fileList.map((e: any) => ({
            ...e,
            url: e.url.startsWith('http') ? e.url : `${window.ATTACHMENT_URL}/api/oss/${e.url}`,
            objName: e.url.startsWith('http') ? e.url : `${window.ATTACHMENT_URL}/api/oss/${e.url}`
        }));
        setFileList(images);
        form.setFieldValue("imgUrl", images[0].url);
    }

    const getTenantData = async () => {
        // 获取租户下拉数据
        jsonRequest.post("/api/request",
            {
                path: `/zy-api/back/dict/list`,
                method: "get",
                application_key: "zy:application::zy-mall",
                tenant_meta: currentTenant,
                data: {
                    dictKey: 'source_tenant'
                },
            })
            .then((resp: any) => resp.json())
            .then(async ({ data }: any) => {
                setTenantMeta(data.data);
            });
    };

    useEffect(() => {
        getTenantData();
        if (item.id) {
            setFileList([{ url: item.imgUrl }]);
            form.setFieldsValue(item);
        }
    }, []);

    return <Form form={form} name="channel-form" labelCol={{ span: 8 }} wrapperCol={{ span: 16 }} autoComplete="off">
        <Form.Item name="id" hidden>
            <Input disabled />
        </Form.Item >
        <Form.Item label="所属租户" name="tenantMeta" rules={[{ required: true, message: '不能为空' }]}>
            <Select placeholder="请选择频道所属租户"
                options={tenantMeta?.map((e: any) => ({ value: e.tenant, label: e.chineseName }))}
            />
        </Form.Item>
        <Form.Item label="频道名" name="channelName" rules={[{ required: true, message: '不能为空' }]}>
            <Input placeholder="如：系统 会员 社区 点赞" />
        </Form.Item>
        <Form.Item label="唯一键" name="channelKey" rules={[{ required: true, message: '不能为空' }]}>
            <Input placeholder="如：SYSTEM COMMUNITY COMMENT" />
        </Form.Item>
        <Form.Item label="群组排序" name="orderInx" initialValue={0} rules={[{ required: true, message: '不能为空' }]} help="值越大越靠前">
            <InputNumber style={{ width: "100%" }} />
        </Form.Item>
        <Form.Item label="频道图标">
            <UploadComponent path={[currentTenant, "channel"].join("/")}
                listType="picture-card"
                value={fileList}
                showUploadList={false}
                onChange={(fileList: any) => handleUploadSuccess(fileList)}>
                {!!fileList[0] ?
                    <Image src={fileList[0].url} preview={false} />
                    : <div>
                        <PlusOutlined />
                        <div style={{ marginTop: 8 }}>拖到这里</div>
                    </div>}
            </UploadComponent>
        </Form.Item>
        <Form.Item label="图标地址" name="imgUrl" rules={[{ required: true, message: '不能为空' }]}>
            <Input disabled />
        </Form.Item>
        {item?.id && (
            <Form.Item label="是否启用" name="status" rules={[{ required: true, message: '不能为空' }]}>
                <Select value={status} onChange={setStatus} placeholder="请选择是否启用">
                    <Select.Option value={1}>启用</Select.Option>
                    <Select.Option value={2}>停用</Select.Option>
                </Select>
            </Form.Item>
        )}
    </Form>
}