import { useGlobalState } from "../../components/global";
import { Avatar, Badge, Button, Divider, Form, List, message, Modal, Popconfirm } from "antd";
import { PlusSquareTwoTone } from "@ant-design/icons";
import { ChannelForm } from "./Form";
import { useEffect, useState } from "react";
// import InfiniteScroll from "react-infinite-scroll-component";

export default function ChannelList() {
    const { currentTenant, jsonRequest } = useGlobalState();
    const [loading, setLoading] = useState(false);
    const [listData, setData] = useState<any>({});
    // const [current, setCurrent] = useState<number>(1);
    // const [total, setTotal] = useState<number>(0);
    const [form] = Form.useForm();
    let modal: any;
    const handleFormOk = async () => {
        let values = await form.validateFields();
        if (values.id) {
            jsonRequest.post(`/api/request`, {
                path: "/v1/notification/channel/modify",
                method: "post",
                application_key: "zy:services::notification",
                tenant_meta: currentTenant,
                data: {
                    ...values
                }
            })
                .then((resp: any) => resp.json())
                .then((data: any) => {
                    if (data.code === 0) {
                        if (data.data.code) {
                            message.error(data.data.message);
                        } else {
                            message.success("编辑成功");
                        }
                    } else {
                        message.error(data.msg);
                    }
                }
                )
        } else {
            jsonRequest.post(`/api/request`, {
                path: "/v1/notification/channel/add",
                method: "post",
                application_key: "zy:services::notification",
                tenant_meta: currentTenant,
                data: {
                    ...values
                }
            })
                .then((resp: any) => resp.json())
                .then((data: any) => {
                    if (data.code === 0) {
                        if (data.data.code) {
                            message.error(data.data.message);
                        } else {
                            message.success("新增成功");
                        }
                        form.resetFields();
                        handleFormCancel();

                    } else {
                        message.error(data.msg);
                    }
                }
                )
        }
        // setCurrent(1);
        // setData([]);
        getData(listData.pageNumber, listData.pageSize);
    }

    const handleFormCancel = () => {
        form.resetFields();
        modal?.destroy();
    }

    const handleFormModal = (item: any = {}) => {
        if (item) {
            item.orderInx = item.orderIndex || 0;
            item.fileList = [{ url: item.imgUrl }];
        }
        modal = Modal.confirm({
            title: '频道管理',
            content: <ChannelForm form={form} item={item} />,
            width: '30vw',
            onOk: handleFormOk,
            onCancel: handleFormCancel,
            okText: '提交',
            cancelText: '取消'
        });
    }

    useEffect(() => {
        getData(1, 10);
    }, [currentTenant]);

    const getData = async (pageNumber: number, pageSize: number) => {
        if (!currentTenant) return;
        if (loading) {
            return;
        }
        setLoading(true);
        // 获取频道列表
        jsonRequest.post("/api/request",
            {
                path: `/v1/notification/channel/pageQuery`,
                method: "get",
                application_key: "zy:services::notification",
                tenant_meta: currentTenant,
                data: {
                    pageNumber,
                    pageSize
                },
            })
            .then((resp: any) => resp.json())
            .then(async ({ data }: any) => {
                // setData([...listData, ...data.list]);
                // setTotal(data.total);
                setData(data);
                setLoading(false);
            })
            .catch(() => {
                setLoading(false);
            });
    };

    const handleDelete = (item: any) => {
        jsonRequest.post("/api/request", {
            path: `/v1/notification/channel/delete`,
            method: "post",
            application_key: "zy:services::notification",
            tenant_meta: currentTenant,
            data: { id: item.id },
        }).then((resp: any) => resp.json())
            .then(() => {
                message.success(`删除成功`);
                // setCurrent(1);
                // setData([]);
                getData(listData.pageNumber, listData.pageSize);
            })
    }

    return <div className="page notification-channel">
        <h1>消息通知频道配置 <Divider type="vertical" />
            <Button type="link" icon={<PlusSquareTwoTone />} onClick={() => handleFormModal()}>新增频道</Button></h1>
        <div className="content">
            <List
                itemLayout="horizontal"
                size="large"
                pagination={{
                    onChange: (page, size) => { getData(page, size) },
                    total: listData.total,
                    current: listData.pageNumber,
                    pageSize: listData.pageSize,
                    showQuickJumper: true
                }}
                dataSource={listData.list}
                renderItem={(item: any) => (
                    <List.Item
                        key={item?.id}
                        actions={[
                            <Badge count={item?.orderIndex} />,
                            <Button type="link" onClick={() => handleFormModal(item)}>编辑</Button>,
                            <Popconfirm
                                title="确定删除？"
                                onConfirm={() => handleDelete(item)}
                                okText="是"
                                cancelText="否"
                            >
                                <Button type="link" danger>删除</Button>
                            </Popconfirm>
                        ]}
                    >
                        <List.Item.Meta
                            avatar={<Avatar src={item?.imgUrl} />}
                            title={<b>{item?.channelName}</b>}
                            description="这里会显示最新一条消息的摘要"
                        />
                    </List.Item>
                )}
            />
        </div>
        {/* <div
            id="scrollableDiv"
            style={{
                height: 400,
                overflow: 'auto',
                padding: '0 16px',
                border: '1px solid rgba(140, 140, 140, 0.35)',
            }}
        >
            <InfiniteScroll
                dataLength={listData.length}
                next={() => {
                    setCurrent(old => {
                        getData(old + 1);
                        return old + 1;
                    });
                }}
                hasMore={listData.length < total}
                loader={<Skeleton avatar paragraph={{ rows: 1 }} active />}
                endMessage={<Divider plain>It is all, nothing more 🤐</Divider>}
                scrollableTarget="scrollableDiv"
            >
                <List
                    dataSource={listData}
                    renderItem={(item: any) => (
                        <List.Item
                            key={item?.id}
                            actions={[
                                <Badge count={item?.orderIndex} />,
                                <Button type="link" onClick={() => handleFormModal(item)}>编辑</Button>,
                                <Popconfirm
                                    title="确定删除？"
                                    onConfirm={() => handleDelete(item)}
                                    okText="是"
                                    cancelText="否"
                                >
                                    <Button type="link" danger>删除</Button>
                                </Popconfirm>
                            ]}
                        >
                            <List.Item.Meta
                                avatar={<Avatar src={item?.imgUrl} />}
                                title={<b>{item?.channelName}</b>}
                                description="这里会显示最新一条消息的摘要"
                            />
                        </List.Item>
                    )}
                />
            </InfiniteScroll>
        </div> */}
    </div>
}