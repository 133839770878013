import { Form, Input, Divider, Image, Button, Alert, Card, Select, Radio } from "antd";
import { useEffect, useState } from "react";
import { useGlobalState } from "../../components/global";
import UploadComponent from "../../components/UploadComponent";
import { PlusOutlined } from "@ant-design/icons";
import { JsonEditor } from "jsoneditor-react18";
import 'react-quill/dist/quill.snow.css';
import Ajv from 'ajv';
import ace from 'brace';

import 'brace/mode/json';
import 'brace/theme/github';
import ReactQuill from "react-quill";

const ajv = new Ajv({ allErrors: true, verbose: true });
declare var window: any;

interface FormValues {
    sceneName?: string;
    sceneKey?: string;
    imgUrl?: string;
    content?: {
        logo?: string;
        title?: string;
        brief?: string;
        content?: string;
    };
    external?: [
        {
            type?: string;
            text?: string;
            url?: string;
            params?: any;
        }
    ];
    defaultPush?: {
        title?: string;
        content?: string;
        url?: string;
        params?: any;
    };
    variables?: {}
}

export function SceneForm({ form, initialValues, channelData }: any) {
    const [formValues, setFormValues] = useState<FormValues>(initialValues || {});
    const [fileList, setFileList]: any = useState([]);
    const { currentTenant } = useGlobalState()
    const [jsonValue, setJsonValue] = useState({});
    const jsonEditorProps = {
        mode: "code",
        schema: null,
        value: jsonValue,
        theme: "ace/theme/github",
        ajv: ajv,
        ace: ace,
        onChange: (value: any) => {
            setJsonValue(value);
            // 处理 JSON 编辑器的值变化
            console.log("JSON Editor value changed:", value);
        },
        onError: (error: any) => {
            // 处理 JSON 编辑器的错误
            console.error("JSON Editor error:", error);
        }
    };

    const onValuesChange = (changedValues: any, allValues: any) => {
        setFormValues(allValues);
    };

    const handleUploadSuccess = (fileList: any) => {
        const images = fileList.map((e: any) => ({
            ...e,
            url: e.url.startsWith('http') ? e.url : `${window.ATTACHMENT_URL}/api/oss/${e.url}`,
            objName: e.url.startsWith('http') ? e.url : `${window.ATTACHMENT_URL}/api/oss/${e.url}`
        }));
        setFileList(images);
        form.setFieldsValue({
            content: {
                logo: images[0].url,
            },
        });
    }

    useEffect(() => {
        if (channelData.length > 0) {
            const defaultOption = channelData.find((e: any) => e?.key === initialValues?.channelKey);
            if (defaultOption) {
                form.setFieldValue('icon', defaultOption.icon);
                form.setFieldValue('channelName', defaultOption.name);
                form.setFieldValue('tenantMeta', defaultOption.tenantMeta);
            }
        }
        if (initialValues?.id) {
            form.setFieldsValue(initialValues);
            setFileList([{ url: initialValues?.content?.logo }]);
        }
    }, []);

    const modules = {
        toolbar: [
            [{ 'header': [1, 2, 3, 4, 5, 6, false] }],
            ['bold', 'italic', 'underline', 'strike', 'blockquote'],
            [{ 'color': [] }, { 'background': [] }],
            [{ 'script': 'sub' }, { 'script': 'super' }],
            [{ 'list': 'ordered' }, { 'list': 'bullet' }],
            [{ 'indent': '-1' }, { 'indent': '+1' }],
            ['link', 'image'],
            ['clean'],
        ]
    };

    const renderContentField = (type: number) => {
        switch (type) {
            case 1:
                return (
                    <Form.Item name={["content", "content"]} label="内容" rules={[{ required: true, message: '不能为空' }]}>
                        <Input.TextArea />
                    </Form.Item>
                );
            case 2:
                return (
                    <Form.Item name={["content", "content"]}
                        style={{ height: 300 }} label="内容" rules={[{ required: true, message: '不能为空' }]}>
                        <ReactQuill
                            theme="snow"
                            modules={modules}
                            style={{ height: 200 }}
                            placeholder="请填写问题详情"
                        />
                    </Form.Item>
                );
            default:
                return null;
        }
    };

    return <Form
        form={form}
        onValuesChange={onValuesChange}
        size="small"
        autoComplete="off"
        name="scene-form"
        initialValues={initialValues ? initialValues : {
            content: { logo: '', type: 1, title: '', brief: '' },
            external: [{ type: 0, text: '', url: '', params: {} }],
            variables: {}
        }}
        style={{ display: "flex" }}
        labelCol={{ span: 6 }}
        wrapperCol={{ span: 16 }}
    >
        <div style={{ width: "38%" }}>
            <Divider>频道设置</Divider>
            <Form.Item name="id" hidden>
                <Input disabled />
            </Form.Item>
            <Form.Item name="channelName" hidden>
                <Input disabled />
            </Form.Item>
            <Form.Item name="tenantMeta" hidden>
                <Input disabled />
            </Form.Item>
            <Form.Item name="icon" hidden>
                <Input disabled />
            </Form.Item>
            <Form.Item name="sceneName" label="场景名" rules={[{ required: true, message: '不能为空' }]}>
                <Input placeholder="如：系统 会员 社区 点赞" disabled={initialValues?.id} />
            </Form.Item>
            <Form.Item name="sceneKey" label="唯一键" rules={[{ required: true, message: '不能为空' }]}>
                <Input placeholder="如：SYSTEM COMMUNITY COMMENT" disabled={initialValues?.id} />
            </Form.Item>
            <Form.Item name="channelKey" label="所属频道" rules={[{ required: true, message: '不能为空' }]}>
                <Select
                    onChange={(value: string, option: any) => {
                        form.setFieldValue('icon', option?.icon);
                        form.setFieldValue('channelName', option?.label);
                        form.setFieldValue('tenantMeta', option?.tenantMeta);
                    }}
                    options={channelData?.map((e: any) => ({ value: e?.key, label: e?.name, icon: e?.icon, tenantMeta: e?.tenantMeta }))}
                />
            </Form.Item>
            <Form.Item label="默认头像" help="可不填">
                <UploadComponent path={[currentTenant, "channel"].join("/")}
                    listType="picture-card"
                    value={fileList}
                    showUploadList={false}
                    onChange={(fileList: any) => handleUploadSuccess(fileList)}>
                    {!!fileList[0] ?
                        <Image src={fileList[0].url} preview={false} />
                        : <div>
                            <PlusOutlined />
                            <div style={{ marginTop: 8 }}>拖到这里</div>
                        </div>}
                </UploadComponent>
            </Form.Item>
            <Form.Item name={["content", "logo"]} label="图标地址" >
                <Input disabled />
            </Form.Item>

            <Divider>消息内容设置</Divider>
            <Form.Item name={["content", "type"]} label="类型" rules={[{ required: true, message: '不能为空' }]}>
                <Radio.Group
                    options={[
                        { label: '文本', value: 1 },
                        { label: '富文本', value: 2 },
                        { label: '社区问答', value: 3 },
                        { label: '社区评论', value: 4 },
                        { label: '社区评论点赞', value: 5 }
                    ]}
                    defaultValue={0}
                    optionType="button"
                    buttonStyle="solid"
                    disabled={initialValues?.content?.type && initialValues.content.type !== 0 && initialValues.content.type !== 1}
                />
            </Form.Item>
            <Form.Item name={["content", "title"]} label="标题" rules={[{ required: true, message: '不能为空' }]} help="消息列表展示">
                <Input placeholder="如：您的发帖被举报下架" />
            </Form.Item>
            <Form.Item name={["content", "brief"]} label="简介" rules={[{ required: true, message: '不能为空' }]} help="在频道列表时展示">
                <Input />
            </Form.Item>
            {(() => {
                const values = form.getFieldsValue();
                const type = values.content?.type;
                return renderContentField(type);
            })()}

            <Divider>附录设置</Divider>
            <Form.Item name="variables" label="附录">
                <JsonEditor {...jsonEditorProps} mode="code" />
            </Form.Item>
        </div>
        <div style={{ width: "38%", margin: " 0 20px" }}>
            <Divider>额外操作</Divider>
            <Form.List name="external">
                {(fields, { add, remove }) => (
                    <div style={{ display: 'flex', rowGap: 16, flexDirection: 'column' }}>
                        {fields.map((field) => (
                            // <Card
                            //     size="small"
                            //     title={`Item ${field.name + 1}`}
                            //     key={field.key}
                            //     extra={
                            //         <CloseOutlined
                            //             onClick={() => {
                            //                 remove(field.name);
                            //             }}
                            //         />
                            //     }
                            // >
                            <div key={field.key}>
                                <Form.Item name={[field.name, "type"]} label="类型">
                                    <Radio.Group
                                        options={[{ label: '按钮', value: 1 }, { label: '无', value: 0 }]}
                                        defaultValue={0}
                                        optionType="button"
                                        buttonStyle="solid"
                                    />
                                </Form.Item>
                                {(() => {
                                    const values = form.getFieldsValue();
                                    const type = values.external?.[field.name]?.type;
                                    return type === 1 ? (
                                        <>
                                            <Form.Item name={[field.name, "text"]} label="按钮文本" help="此处可以留空，若留空，在消息列表中将不会展示。点击消息后直接执行操作。">
                                                <Input placeholder="如：去处理 去申诉 去查看" />
                                            </Form.Item>
                                            <Form.Item name={[field.name, "url"]} label="按钮URL">
                                                <Input placeholder="可以是外部网址或者APP的内部页面" />
                                            </Form.Item>
                                            <Form.Item name={[field.name, "params"]} label="附加参数" initialValue={{}}>
                                                <JsonEditor {...jsonEditorProps} mode="code" />
                                            </Form.Item>
                                        </>
                                    ) : null;
                                })()}
                            </div>
                            // </Card>
                        ))}

                        {/* <Button type="dashed" onClick={() => add()} block>
                            + Add Item
                        </Button> */}
                    </div>
                )}
            </Form.List>
            <Divider>推送配置</Divider>
            <Form.Item name={["defaultPush", "title"]} label="推送标题" >
                <Input />
            </Form.Item>
            <Form.Item name={["defaultPush", "content"]} label="推送内容" >
                <Input.TextArea />
            </Form.Item>
            <Form.Item name={["defaultPush", "url"]} label="点击打开URL">
                <Input placeholder="APP的内部页面" />
            </Form.Item>
            <Form.Item name={["defaultPush", "params"]} label="附加参数" initialValue={{}}>
                <JsonEditor {...jsonEditorProps} mode="code" />
            </Form.Item>
        </div>
        <div style={{ width: "24%" }}>
            <Divider>频道列表预览</Divider>
            <div style={{ display: 'flex', backgroundColor: 'rgb(248, 248, 248)', padding: '10px', borderRadius: '5px' }}>
                <div style={{ padding: '5px' }}>{form.getFieldValue('icon') && (<Image style={{ width: 60, height: 60 }} src={form.getFieldValue('icon')} />)}</div>
                <div>
                    <span>{form.getFieldValue('channelName')}</span>
                    <br />
                    <span style={{ color: 'gray' }}>{form.getFieldValue(["content", "brief"])}</span>
                    <br />
                    <span style={{ color: 'gray' }}>01-01 00:00:00</span>
                </div>
            </div>
            <Divider>频道内消息列表预览</Divider>
            <div style={{ backgroundColor: 'rgb(248, 248, 248)', padding: '10px', borderRadius: '5px' }}>
                {form.getFieldValue(["content", "logo"]) && (<Image style={{ width: 20, height: 20 }} src={form.getFieldValue(["content", "logo"])} />)}
                <span>&nbsp;{form.getFieldValue(["content", "title"]) || "请在左边设置标题"}</span><br />

                {form.getFieldValue(["content", "type"]) === 1 && (
                    <>
                        <span style={{ color: 'gray' }}>{form.getFieldValue(["content", "content"])}</span>
                        <br />
                    </>
                )}
                {form.getFieldValue(["content", "type"]) === 2 && (
                    <span style={{ color: 'gray' }} dangerouslySetInnerHTML={{ __html: form.getFieldValue(['content', 'content']) }} />
                )}
                {form.getFieldValue(["content", "type"]) > 2 && (
                    <div style={{ maxHeight: '200px', overflowY: 'auto', color: 'rgb(136, 136, 136)' }}>
                        <div style={{ padding: '5px 10px', background: 'rgb(255, 255, 255)', margin: '5px' }}>
                            <div><b>中医应该怎么学？</b></div>
                            <div style={{ fontSize: '11px' }}>
                                阅读 12123 · 赞同 234
                            </div>
                        </div>
                    </div>
                )}

                {form.getFieldValue("external")?.[0]?.text && (<><Button type="link">{form.getFieldValue("external")?.[0]?.text}</Button><br /></>)}
                <span style={{ color: 'gray' }}>01-01 00:00:00</span>
            </div>
            <Divider>推送预览</Divider>
            <div style={{ backgroundColor: 'rgb(248, 248, 248)', padding: '10px', borderRadius: '5px' }}>
                <span>{form.getFieldValue(["defaultPush", "title"])}</span>
                <br />
                <span style={{ color: 'gray' }}>{form.getFieldValue(["defaultPush", "content"])}</span>
            </div>
            <Divider>附录</Divider>
            <Alert type="warning" message="此处为变量，可以加在'简介'、'标题'、'内容'、'URL'、'附加参数'里使用" ></Alert>
            <Card>
                {Object.entries(form.getFieldValue('variables') || {}).map(([key, value]: any) => (
                    <div key={key}>
                        <span style={{ color: 'rgb(254,172,23)' }}>{'{'}{key}{'}'}</span>
                        <span>:{value}</span>
                    </div>
                ))}
            </Card>
        </div>
    </Form>
}