import { Avatar, Button, Divider, List, Popover, Radio, Tooltip } from "antd";
import { memo, useCallback, useEffect, useState } from "react";
import { useGlobalState } from "../../components/global";
import ownerIcon from "../../components/owner_icon";
import './List.css';

export default function MessageList() {
  const { currentTenant, jsonRequest } = useGlobalState();
  const [loading, setLoading] = useState(false);
  const [listData, setData] = useState<any>({});
  const [channelData, setChannelData] = useState<any>([]);
  const [channelKey, setChannelKey] = useState<any>('');
  const [ownerAvatarDict, setOwnerAvatarDict]: any = useState({});

  useEffect(() => {
    getChannelData();
  }, []);

  useEffect(() => {
    getData(1, 9);
  }, [currentTenant, channelKey]);

  const getData = async (pageNumber: number, pageSize: number) => {
    if (loading) return;
    if (!currentTenant) return;
    setLoading(true);
    // 获取已发送的消息通知列表
    jsonRequest.post("/api/request",
      {
        path: `/v1/notification/pageQueryByPlatform`,
        method: "get",
        application_key: "zy:services::notification",
        tenant_meta: currentTenant,
        data: {
          pageNumber,
          pageSize,
          channelKey
        },
      })
      .then((resp: any) => resp.json())
      .then(async ({ data }: any) => {
        setData(data);
        const ownerIDs = data.entries
          .filter((entry: any) => !(entry.target?.ownerId?.length === 1 && entry.target?.ownerId?.includes('all')))
          .flatMap((entry: any) => entry.target?.ownerId);
        ownerIcon(currentTenant, jsonRequest, data.entries[0].target.userType, ownerIDs)
          .then((resp) => resp.json())
          .then(({ data }) =>
            setOwnerAvatarDict({ ...ownerAvatarDict, ...data }),
          );
        setLoading(false);
      })
      .catch(() => {
        setLoading(false);
      });
  };

  const getChannelData = async () => {
    // 获取频道下拉数据
    jsonRequest.post("/api/request",
      {
        path: `/v1/notification/channel/queryAll`,
        method: "get",
        application_key: "zy:services::notification",
        tenant_meta: currentTenant,
        data: {},
      })
      .then((resp: any) => resp.json())
      .then(async ({ data }: any) => {
        setChannelData(data);
      });
  };

  const renderOwner = (item: any, ownerAvatarDict: any) => {
    if (item?.target?.ownerId?.includes("all")) {
      return (
        <div className="user-avatar-wrap">
          全部
        </div>
      );
    }
    const ownerId = item.target?.ownerId?.[0];
    const ownerInfo = ownerAvatarDict[ownerId];

    return (
      <div className="user-avatar-wrap">
        <Avatar src={ownerInfo?.avatar} size={40} />
        <Tooltip title={`${ownerInfo?.nickname || ownerId}`}>
          <span className="nickname" style={{ maxWidth: 44 }}>
            {ownerInfo?.nickname || ownerId}
          </span>
        </Tooltip>
      </div>
    );
  };

  const LazyLoadedAvatars = memo(({ ownerIdList, ownerAvatarDict }: { ownerIdList: string[]; ownerAvatarDict: any }) => {
    const [loadedAvatars, setLoadedAvatars] = useState<{ [key: string]: boolean }>({});

    const loadAvatars = useCallback(async () => {
      const newLoadedAvatars = ownerIdList.reduce((acc: { [key: string]: boolean }, id: string) => {
        acc[id] = true;
        return acc;
      }, {});

      setLoadedAvatars(newLoadedAvatars);
    }, [ownerIdList]);

    useEffect(() => {
      loadAvatars();
    }, [loadAvatars]);


    return (
      <div className="popover-content">
        {ownerIdList.map((id: string, index: number) => (
          <div key={index} className="user-avatar-wrap">
            <Avatar src={loadedAvatars[id] ? ownerAvatarDict[id]?.avatar : undefined} size={40} />
            <Tooltip title={`${ownerAvatarDict[id]?.nickname}`}>
              <span className="nickname" style={{ maxWidth: 40 + 4 }}>
                {ownerAvatarDict[id]?.nickname || id}
              </span>
            </Tooltip>
          </div>
        ))}
      </div>
    );
  });

  const renderAvatars = (item: any, ownerAvatarDict: any) => {
    if (item?.target?.ownerId?.length > 1) {
      return (
        <div className="user-avatar-wrap">
          <Popover content={<LazyLoadedAvatars ownerIdList={item.target.ownerId.slice(1)} ownerAvatarDict={ownerAvatarDict} />}>
            <Avatar style={{ transform: 'scale(1)' }} size={40}>
              +{item.target.ownerId.length - 1}
            </Avatar>
          </Popover>
        </div>
      );
    }
    return null;
  };

  return <div className="page notification-scene">
    <h1>已发送的消息通知</h1>
    <div style={{ display: "flex", alignItems: "center" }}>
      频道：<Radio.Group
        options={[
          { value: '', label: '全部' },
          ...channelData?.map((e: any) => ({ value: e?.key, label: e?.name })) || []
        ]}
        value={channelKey}
        onChange={(e) => setChannelKey(e?.target?.value)}
        optionType="button"
      />
    </div>
    <br />
    <div className="content">
      <List
        loading={loading}
        rowKey={d => d.meta}
        dataSource={listData?.entries}
        pagination={{
          onChange: (page, size) => { getData(page, size) },
          total: listData.total_entries,
          current: listData.page_number,
          pageSize: listData.page_size,
          showQuickJumper: true
        }}
        grid={{ gutter: 16, column: 3 }}
        itemLayout="vertical"
        size="large"
        renderItem={(item: any) => (
          <List.Item
            key={item.id}
            className="item-scene"
            actions={[
              <>
                {item.inserted_at}
                <Divider type="vertical" />
                {item.external[0].type === 1 && (
                  <Popover content={item.external[0].url}>
                    <Button type="dashed" danger>{item.external[0].text || "列表中点击直接执行"}</Button>
                  </Popover>
                )}
              </>
            ]}
          >
            <List.Item.Meta
              avatar={item?.content?.logo && (<Avatar src={item?.content?.logo} />)}
              title={
                <div>
                  <span style={{ color: 'orange' }}>[{item?.channel_name}]</span>
                  <span>{item?.content?.brief}</span>
                </div>}
              description={<div style={{ color: 'rgb(102, 102, 102)', display: 'flex' }}>
                <div style={{ height: '120px', overflowY: 'auto', flexGrow: 1 }}>
                  <div style={{ margin: '14px 0' }}>{item?.content?.content_text}</div>
                </div>
                <div style={{ marginLeft: '10px', transform: 'translate(0px, -30px)' }}>
                  <div className="ant-avatar-group">
                    {renderOwner(item, ownerAvatarDict)}
                    {!item?.target?.ownerId?.includes("all") && renderAvatars(item, ownerAvatarDict)}
                  </div>
                </div>
              </div>}
            />
          </List.Item >
        )}
      >
      </List>
    </div>
  </div >
}