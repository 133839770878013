import { Menu } from 'antd';
import { FormatPainterOutlined, NodeExpandOutlined, NotificationOutlined } from '@ant-design/icons';
import { useLocation, Link } from "react-router-dom";

function SideMenu() {
    const location = useLocation();
    const pathName = location.pathname.split("/").slice(0, 3).join("/");
    const fullPathName = location.pathname;

    return <Menu style={{ width: 256 }} defaultSelectedKeys={[pathName, fullPathName]} mode="inline" >
        <Menu.ItemGroup key="notification" title="消息通知">
            <Menu.Item key="/notification/channel/index" icon={<NodeExpandOutlined />}>
                <Link to="/notification/channel/index">
                    <div style={{ display: "flex", justifyContent: "space-between" }}>
                        <span>频道</span>
                    </div>
                </Link>
            </Menu.Item>
            <Menu.Item key="/notification/scene/index" icon={<FormatPainterOutlined />}>
                <Link to="/notification/scene/index">
                    <div style={{ display: "flex", justifyContent: "space-between" }}>
                        <span>场景</span>
                    </div>
                </Link>
            </Menu.Item>
            <Menu.Item key="/notification/message/index" icon={<NotificationOutlined />}>
                <Link to="/notification/message/index">
                    <div style={{ display: "flex", justifyContent: "space-between" }}>
                        <span>已发送的消息通知</span>
                    </div>
                </Link>
            </Menu.Item>
        </Menu.ItemGroup>
    </Menu >
}

export default SideMenu;