import RichTextEditor from "quill-react-commercial";
import "quill-react-commercial/lib/index.css";
import { useRef, useState } from "react";

export default function CustomQuill({
                                        placeholder = "请输入...",
                                        imgUploadApi,
                                        value,
                                        onChange,
                                        quillRef,
                                        setStylizeText,
                                        ...others
                                    }: any) {
    const quill: any = useRef();
    const [hasUpdate, setHasUpdate]: any = useState(false);

    const baseColors = [
        "rgb(0, 0, 0)", "rgb(230, 0, 0)", "rgb(255, 153, 0)", "rgb(255, 255, 0)", "rgb(0, 138, 0)", "rgb(0, 102, 204)", "rgb(153, 51, 255)",
        "rgb(255, 255, 255)", "rgb(250, 204, 204)", "rgb(255, 235, 204)", "rgb(255, 255, 204)", "rgb(204, 232, 204)", "rgb(204, 224, 245)", "rgb(235, 214, 255)",
        "rgb(187, 187, 187)", "rgb(240, 102, 102)", "rgb(255, 194, 102)", "rgb(255, 255, 102)", "rgb(102, 185, 102)", "rgb(102, 163, 224)", "rgb(194, 133, 255)",
        "rgb(136, 136, 136)", "rgb(161, 0, 0)", "rgb(178, 107, 0)", "rgb(178, 178, 0)", "rgb(0, 97, 0)", "rgb(0, 71, 178)", "rgb(107, 36, 178)",
        "rgb(68, 68, 68)", "rgb(92, 0, 0)", "rgb(102, 61, 0)", "rgb(102, 102, 0)", "rgb(0, 55, 0)", "rgb(0, 41, 102)", "rgb(61, 20, 102)",
    ];
    const extendColors = ["rgb(88, 190, 191)", "rgb(193, 161, 107)"];

    const modules = {
        table: {},
        codeHighlight: true,
        imageHandler: { 
          imgUploadApi, 
          imageAccept: 'image/png, image/gif, image/jpeg, image/bmp, image/x-icon, image/webp' 
        },
        toolbarOptions: [
            ["undo", "redo", "clean"],
            [{ font: ["wsYaHei", "songTi", "serif", "arial"] }, { size: ["16px", "10px", "12px", "14px", "18px", "36px"] }],
            [{ color: [...baseColors, ...extendColors] }, { background: [] }],
            ["bold", "italic", "underline", "strike"],
            [{ list: "ordered" }, { list: "bullet" }, { list: "check" }, { indent: "-1" }, { indent: "+1" }, { align: [] }],
            ["blockquote", "code-block", "link", "image", { script: "sub" }, { script: "super" }, "table", "divider"],
        ],
    };
    
    const getStylizeHtml = (html: any) => {
        const parser = new DOMParser();
        const doc = parser.parseFromString(html, "text/html");
        // 遍历所有的元素
        const elements = doc.body.querySelectorAll("*");
        elements.forEach((e: any) => {
            const classList = e.classList;
            const styleList = e.style;

            const fontSize = styleList["font-size"];
            if (fontSize) {
                styleList.removeProperty("font-size");
                const className = `font-size-${fontSize.substring(0, fontSize.length - 2)}`;
                if (!classList.contains(className))
                    classList.add(className);
            }
        });
        setStylizeText(doc.body.innerHTML.replace(/\ style=""/gi, ""));
    };

    const onEditorChange = (v: any) => {
        setHasUpdate(true);
        onChange && onChange(quill.current.getSemanticHTML(), quill.current.getText());
        setStylizeText && getStylizeHtml(quill.current.getSemanticHTML());
    };

    const getQuill = (quillIns: any) => {
        quill.current = quillIns;
        quill.current.blur();
        quillRef && (quillRef.current = quillIns);
    };

    return <RichTextEditor modules={modules} placeholder={placeholder} i18n="zh"
                           {...others} onChange={onEditorChange} getQuill={getQuill}
                           content={hasUpdate ? undefined : (value || <p></p>)} />;
}